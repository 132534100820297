import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { envioBiometria, getBiometriaInstrutor } from "../services/apiService";  // Make sure to import getBiometriaInstrutor
import { AppContext } from "../context/AppContext";
import imageCompression from 'browser-image-compression';
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  RadioGroup,
  Radio,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    padding: theme.spacing(2),
    textAlign: "center",
    position: "relative",
  },
  title: {
    position: "absolute",
    top: theme.spacing(2),
    width: "100%",
    textAlign: "center",
  },
  content: {
    marginTop: theme.spacing(8),
    width: "100%",
    maxWidth: 600,
    textAlign: "center",
  },
  photo: {
    maxWidth: "100%",
    maxHeight: "300px",
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginBottom: theme.spacing(3),
    width: "100%", // Garante que o formulário ocupe toda a largura disponível
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const EnvioFuncionarioPage = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    capturedImage,
    codAulaPratica,
    codAluno,
    selectedFinger,
    tipoRegistro,
  } = useContext(AppContext);
  const [etapa, setEtapa] = useState(1);
  const [description, setDescription] = useState({
    titulo: "Aviso",
    mensagem: "Mensagem",
  });
  const [entradaRealizada, setEntradaRealizada] = useState(false);


  useEffect(() => {
    const fetchStatus = async () => {
      setLoading(true);
      try {
        const status = await getBiometriaInstrutor(codAulaPratica, codAluno);
        const entrada = status.find(s => s.etapa === 1);
        if (entrada && entrada.dataHoraColeta) {
          setEntradaRealizada(true);
          setEtapa(2); // Automatically select 'Saída' if 'Entrada' was already done
        }
      } catch (error) {
        console.error("Erro ao buscar status da biometria:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();
  }, [codAulaPratica, codAluno]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const cleanedImage = capturedImage.replace(
        /^data:image\/[a-z]+;base64,/,
        ""
      );

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
        fileType: "image/jpeg", // Especifica que queremos a saída em JPEG
      };

      try {
        const compressedFile = await imageCompression(cleanedImage, options);
        const jpegUrl = URL.createObjectURL(compressedFile);
        ////console.log("Imagem convertida", jpegUrl);
        // setJpegImage(jpegUrl);
      } catch (error) {
        console.error("Erro ao converter a imagem:", error);
      }

      const response = await envioBiometria(
        codAulaPratica,
        etapa,
        5,
        tipoRegistro,
        selectedFinger,
        cleanedImage
      );
      const { codigoRetornoBiometria, mensagemBiometria } = response;

      if (codigoRetornoBiometria === 1) {
        navigate("/sucesso-funcionario", {
          state: { message: "Biometria realizada com sucesso!" },
          replace: true,
        });
      } else {
        const errorMessage =
          mensagemBiometria || "Erro desconhecido na biometria.";
        navigate("/falha-funcionario", {
          state: { message: errorMessage },
          replace: true,
        });
      }
      // navigate('/sucesso-funcionario', { state: { message: "Biometria realizada com sucesso!" }, replace: true });
    } catch (error) {
      console.error("Erro no envio", error);
      navigate("/falha-funcionario", {
        state: { message: error.response?.data?.message || error.message },
        replace: true,
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Typography variant="h3" component="h1" className={classes.title}>
        Enviar Biometria - Funcionário
      </Typography>
      <div className={classes.content}>
        {capturedImage && (
          <img
            src={capturedImage}
            alt="Foto Capturada"
            className={classes.photo}
          />
        )}
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">
            Qual etapa você está fazendo?
          </FormLabel>
          <RadioGroup
            aria-label="etapa"
            name="etapa"
            value={etapa}
            onChange={(ev) => setEtapa(Number(ev.target.value))}
          >
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Radio
                      value={1}
                      color="primary"
                      disabled={entradaRealizada}
                    />
                  }
                  label="Entrada"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Radio value={2} color="primary" />}
                  label="Saída"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
        <div className={classes.buttonContainer}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSend}
            style={{ backgroundColor: "green" }}
          >
            Enviar
          </Button>
          <Button
            fullWidth
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => navigate("/capture-funcionario")}
          >
            Refazer
          </Button>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{description.titulo}</DialogTitle>
        <DialogContent>
          <Typography>{description.mensagem}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EnvioFuncionarioPage;
