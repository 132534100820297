import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { formatISO } from "date-fns";
import { useMediaQuery, useTheme } from "@material-ui/core"; // Importa useMediaQuery e useTheme
import "./AulasPage.css";
import { AppContext } from "../context/AppContext";
import { getRetornoAula, getAulas } from "../services/apiService";
import {
  Box,
  Button,
  Card,
  CardContent,
  makeStyles,
  Container,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import CryptoJS from 'crypto-js';

const useStyles = makeStyles((theme) => ({
  nomeAluno: {
    fontSize: "1.5rem",
    fontWeight: "bolder",
    textAlign: "left",
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
  nomeInstrutor: {
    fontSize: "1.2rem",
    textAlign: "left",
    flexGrow: 1,
    marginBottom: "0.8rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.85rem",
    },
  },
  categoria: {
    fontSize: '1rem',
    fontWeight: "bold",
    textAlign: "right",
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  captureButton: {
    backgroundColor: "blue",
    color: "white",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  captureButtonDisabled: {
    backgroundColor: "gray",
    color: "white",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  cardContainer: {
    position: "relative",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  buttonContainer: {
    marginTop: "1.5rem",
  },
}));

const AulasPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs')); // Detecta se a tela é pequena (celular)
  const [aulas, setAulas] = useState([]);
  const [statusEntrada, setStatusEntrada] = useState({});
  const [statusSaida, setStatusSaida] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();
  const { setCodAulaPratica, setCodAluno } = useContext(AppContext);

  const decryptData = (ciphertext, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const getDecryptedCPF = () => {
    const encryptedCPF = sessionStorage.getItem('cod');
    if (encryptedCPF) {
      const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
      const decryptedCPF = decryptData(encryptedCPF, secretKey);
      return decryptedCPF;
    }
    return null;
  };

  useEffect(() => {
    const fetchAulas = async () => {
      const token = sessionStorage.getItem("cod2");
      const cpf = getDecryptedCPF();
      if (!token || !cpf) {
        alert("Usuário não autenticado!");
        navigate("/");
        return;
      }

      try {
        const data = await getAulas(cpf);
        setAulas(data);

        data.forEach(async (aula) => {
          const status = await getRetornoAula(aula.codAula, cpf);
          const { codigoRetornoBiometria, mensagemBiometria } = status;
          const entrada = status.find((s) => s.etapa === 1);
          const saida = status.find((s) => s.etapa === 2);
          setStatusEntrada((prevStatus) => ({
            ...prevStatus,
            [aula.codAula]: entrada || codigoRetornoBiometria === 1
              ? {
                text: "Realizada às " + formatDate(entrada.dataHoraColeta),
                color: "green",
              }
              : { text: "Entrada não realizada", color: "red" },
          }));
          setStatusSaida((prevStatus) => ({
            ...prevStatus,
            [aula.codAula]: saida || codigoRetornoBiometria === 1
              ? {
                text: "Realizada às " + formatDate(saida.dataHoraColeta),
                color: "green",
              }
              : { text: "Saída não realizada", color: "red" },
          }));
      })
      }
      catch (error) {
        console.error("Erro ao buscar aulas:", error);
        alert("Erro ao buscar aulas");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAulas();
  }, [navigate]);

  
  const formatDate = (dateString) => {
    const options = { hour: "2-digit", minute: "2-digit", second: "2-digit" };
    return new Date(dateString).toLocaleTimeString("pt-BR", options);
  };
  
  const formatDateTime = (dateString) => {
    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleString("pt-BR", options);
  };
  const isCaptureAllowed = (aula) => {
    const now = new Date();
    const aulaInicio = new Date(aula.dataHoraAulaInicio);
    const aulaFim = new Date(aulaInicio.getTime() + aula.qtdAula * 60 * 50 * 1000);
    
    const startCapture = new Date(aulaInicio.getTime() - 27 * 60 * 1000);
    const endCapture = new Date(aulaFim.getTime() - 30 * 1000);
    const startPostAulaCapture = aulaFim;
    const endPostAulaCapture = new Date(aulaFim.getTime() + 39 * 60 * 1000 + 59 * 1000);
    
    return (now >= startCapture && now <= endCapture) || (now >= startPostAulaCapture && now <= endPostAulaCapture);
  };
  
  const handleCaptureViaImage = (aula) => {
    // if (isCaptureAllowed(aula)) {
      setCodAulaPratica(aula.codAula);
      setCodAluno(aula.codAluno);
      navigate('/capture_image_aluno'); // Redireciona para a página de captura via imagem
    // } else {
    //   setModalMessage(
    //     "A Captura de entrada é permitida apenas a partir de 30 minutos antes do início da aula e até 15 minutos após o início da aula. A captura de saída é permitida apenas após o término da aula e até 40 minutos depois."
    //   );
    //   setModalOpen(true);
    // }
  };
  
  const handleCapture = (aula) => {
    if (isCaptureAllowed(aula)) {
      setCodAulaPratica(aula.codAula);
      setCodAluno(aula.codAluno);
      navigate("/capture");
    } else {
      setModalMessage(
        "A Captura de entrada é permitida apenas a partir de 30 minutos antes do início da aula e até 15 minutos após o início da aula. A captura de saída é permitida apenas após o término da aula e até 40 minutos depois."
      );
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Container style={{ paddingTop: "2rem" }}>
      <Typography variant="h3" style={{ textAlign: "center" }} component="h1">
        Lista de Aulas
      </Typography>
      <div>
        {isLoading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          aulas.length > 0 ? (
            aulas.map((aula, index) => (
              <Box m={4} key={index} fontSize="1rem">
                <Card
                  className={classes.cardContainer}
                  style={{ maxWidth: "550px", margin: "1rem auto" }}
                >
                  <CardContent>
                    <div className={classes.headerContainer}>
                      <Typography className={classes.nomeAluno} component="h2">
                        {aula.nomeAluno.trim()}
                      </Typography>
                      <Typography className={classes.categoria} component="h3">
                        Categoria: {aula.categoria}
                      </Typography>
                    </div>
                    <Typography
                      className={classes.nomeInstrutor}
                      variant="body1"
                      component="h3"
                    >
                      Instrutor: {aula.nomeInstrutor}
                    </Typography>
                    <Grid container spacing={1} style={{ margin: "0.3rem 0" }}>
                      <Grid item xs={12} sm={6} style={{ padding: "0" }}>
                        <Typography component="p">
                          Início: {formatDateTime(aula.dataHoraAulaInicio)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ padding: "0" }}>
                        <Typography component="p">
                          Término:{" "}
                          {formatDateTime(
                            new Date(
                              new Date(aula.dataHoraAulaInicio).getTime() +
                              aula.qtdAula * 60 * 50 * 1000
                            )
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          style={{ alignItems: "center", margin: "auto" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ padding: "0" }}>
                        <Typography component="p">
                          Quantidade de Aulas: {aula.qtdAula}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ padding: "0" }}>
                        <Typography component="p">
                          Código da Aula: {aula.codAula}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          style={{ alignItems: "center", margin: "auto" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          padding: "0",
                          color: statusEntrada[aula.codAula]?.color,
                        }}
                      >
                        <Typography component="p">
                          Entrada: {statusEntrada[aula.codAula]?.text}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          padding: "0",
                          color: statusSaida[aula.codAula]?.color,
                        }}
                      >
                        <Typography component="p">
                          Saída: {statusSaida[aula.codAula]?.text}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.buttonContainer}>
                      {isMobile ? ( // Se for celular, mostra apenas "Capturar via Foto"
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            className={`${classes.captureButton} ${!isCaptureAllowed(aula) ? classes.captureButtonDisabled : ''}`}
                            onClick={() => handleCapture(aula)}
                          >
                            Capturar via Foto
                          </Button>
                        </Grid>
                      ) : ( // Se não for celular, mostra os dois botões lado a lado
                        <>
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              size="large"
                              fullWidth
                              className={`${classes.captureButton} ${!isCaptureAllowed(aula) ? classes.captureButtonDisabled : ''}`}
                              onClick={() => handleCapture(aula)}
                            >
                              Capturar via Biometria
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              size="large"
                              fullWidth
                              className={`${classes.captureButton} ${!isCaptureAllowed(aula) ? classes.captureButtonDisabled : ''}`}
                              onClick={() => handleCaptureViaImage(aula)}
                            >
                              Capturar via Foto
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            ))
          ) : (
            <Typography
              variant="h5"
              component="p"
              style={{ textAlign: "center", margin: "2rem auto" }}
            >
              Não foram encontradas aulas para o CPF cadastrado!
            </Typography>
          )
        )}
      </div>
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>Aviso</DialogTitle>
        <DialogContent>
          <Typography>{modalMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AulasPage;
