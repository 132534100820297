import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [codAulaPratica, setCodAulaPratica] = useState(null);
  const [codAluno, setCodAluno] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [selectedFinger, setSelectedFinger] = useState(3);
  const [tipoRegistro, setTipoRegistro] = useState(0);

  return (
    <AppContext.Provider value={{ codAulaPratica, setCodAulaPratica, codAluno, setCodAluno, capturedImage, setCapturedImage, selectedFinger, setSelectedFinger, tipoRegistro, setTipoRegistro }}>
      {children}
    </AppContext.Provider>
  );
};
