import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginFuncionario as login } from '../services/apiService';
import CryptoJS from 'crypto-js';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  CssBaseline,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import logo from '../asset/image/icone1.png'; // Ajuste o caminho conforme necessário

// Função de validação de CPF
const isValidCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove tudo que não é dígito
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f7f9fb',
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: 'red',
  },
}));

const LoginFuncionarioPage = () => {
  const classes = useStyles();
  const [loginInput, setLoginInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    setError(''); // Reset error message

    if (!isValidCPF(loginInput)) {
      setError('CPF inválido!');
      return;
    }

    try {
      const data = await login(loginInput);
      if (data.token) {
        const secretKey = process.env.REACT_APP_ENCRYPTION_KEY; // Acessa a chave do .env
        const encryptedCPF = CryptoJS.AES.encrypt(loginInput, secretKey).toString();

        sessionStorage.setItem('cod2', data.token);
        sessionStorage.setItem('cod', encryptedCPF); // Armazena o CPF criptografado no sessionStorage
        navigate('/aulas-funcionario', { replace: true });
      } else {
        setError('Login falhou!');
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      setError('Erro ao fazer login. Verifique suas credenciais e tente novamente.');
    }
  };

  const handleLoginChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    setLoginInput(value);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.root}>
        <Paper elevation={6} className={classes.paper}>
          <Avatar alt="Logo" src={logo} className={classes.avatar} />
          <Typography component="h1" variant="h5">
            Fazer Login Instrutor
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="cpf"
              label="CPF somente números"
              name="cpf"
              autoComplete="cpf"
              autoFocus
              value={loginInput}
              onChange={handleLoginChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleLogin}
            >
              Entrar
            </Button>
            {error && <Typography className={classes.errorMessage}>{error}</Typography>}
          </form>
          <Box mt={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              Em caso de dúvidas de como acessar, entre em contato com a sua Auto Escola!
            </Typography>
          </Box>
        </Paper>
      </div>
    </Container>
  );
};

export default LoginFuncionarioPage;
